<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="() => options.add ? (options.fromOrder ? handleSubmit(addNewBatchFromOrder) : handleSubmit(addNewBatch)) : handleSubmit(updateBatch)"
    >
      <div class="wrap">
        <p class="title is-size-4-mobile mb-5">
          {{ options.add ? $t("add_batch") : $t("edit_noun") }}
        </p>
        <!-- <div slot="header">
      <div class="is-size-5 px py-4">
        <b> {{ $t("add_batch") }} </b>
      </div>
      <hr class="my-0">
    </div> -->
        <div class="columns is-multiline is-mobile">
          <b-select-validation
            v-model="options.warehouse"
            rules="required"
            :disabled="(options.sold && !$can('update', 'purchasing.override')) || options.fromOrder "
            vid="warehouse"
            :label="$tc('warehouse', 1)"     
            class="column is-6-tablet is-12-mobile"
            @input="setWarehouse(options.warehouse)"
          >
            <template>
              <option
                v-for="opt in options.warehouses"
                :key="opt.id"
                :value="opt.id"
              >
                {{
                  opt.country.country + " - " + opt.currency.code
                }}
              </option>
            </template>
          </b-select-validation>
          <div class="column is-6-tablet is-12-mobile mt-2">
            <b-switch
              v-model="options.internal"
            >
              <div class="is-size-9 has-text-grey">
                {{
                  $t("internal_use")
                }}
              </div>
            </b-switch>
          </div>    
          <b-input-validation            
            v-model="options.stock"
            :label="$t('stock')"
            :placeholder="placeholders.number['1']"
            type="number"
            rules="required"
           
            class="column is-6-tablet is-6-mobile"
          />        
          <b-input-validation            
            v-model="options.purchase_price"
            :label="$t('purchase_price')"
            :placeholder="placeholders.price.decimal"
            :suffix="options.warehouse ? options.warehouses[options.warehouses.findIndex(i => i.id == options.warehouse)].currency.symbol : options.warehouses[0].currency.symbol"
            type="number"
            rules="required"          
            class="column is-6-tablet is-6-mobile"
          />          
               
          <!-- <div class="column is-12 is-size-8 has-text-grey">
            {{ $t('expire_at') }} ({{ $t('optional') }})
          </div>
          <b-datepicker
            v-model="options.expire_at"
            :min-date="new Date('01/01/2013')"
            size="is-small"
            inline
            class="column pt-0"
          />   -->
          <b-datepicker-validation
            v-model="options.expire_at"
            class="column is-6-tablet is-6-mobile"
            :label="`${$t('expire_at')} ` + ((options.expiryRequired && !options.internal )? '' : `(${$t('optional')})`)"
            :vid="'expire_at'"
            locale="fr-CA"
            :rules="(options.expiryRequired && !options.internal) ? 'required' : null"
            :min-date="minDate"
            format="YYYY-MM-DD"
            :placeholder="placeholders.date.day3"
          />
          <div class="column is-12"> 
            <b-button
              type="is-primary"
              tag="input"
              native-type="submit"
              :value="$t('validate')"
              :loading="loading"
              :disabled="loading"
            >
              <!-- @click="options.add ? addNewBatch() : updateBatch() ; close()" -->
              {{ $t("validate") }}
            </b-button>
          </div>
        </div>
      </div>
    </form>
  </validationobserver>
</template>

<script>

export default { 
  props: {
    options: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    minDate: function() {
      var currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() - 1);
      var dateInMillis = currentDate.getTime();
      var newDate = new Date(dateInMillis);
      return newDate;
    }
  },

  created() {
    if (this.options.fromOrder) {
      this.options.purchase_price =  this.options.order_purchasePrice;
      this.options.internal = true;
    }
    else if (this.options.warehouses.length === 1){
      this.options.purchase_price = this.options.warehouses[0].purchase_price && this.options.warehouses[0].purchase_price.value ? this.options.warehouses[0].purchase_price.value : null;
      this.options.warehouse = this.options.warehouses[0].id;
    }
  },

  methods: {
  setWarehouse(warehouse) {
    let index = this.options.warehouses.findIndex(i => i.id == warehouse);
    this.options.purchase_price = this.options.warehouses[index].purchase_price && this.options.warehouses[index].purchase_price.value ? this.options.warehouses[index].purchase_price.value : null;     

  },
	addNewBatch() { 
    this.loading = true;
    setTimeout(() => {
      this.$bus.$emit("add-new-batch", {newPrice: this.options.purchase_price, newStock: this.options.stock, newDatetime: this.options.expire_at  || null, internalUse: this.options.internal, fromOrder: this.options.fromOrder, warehouse_id: this.options.warehouse })
          }, 1000);
	},
  addNewBatchFromOrder() { 
    this.loading = true;
    setTimeout(() => {
      this.$bus.$emit("add-new-batch-from-order", {newPrice: this.options.purchase_price, newStock: this.options.stock, newDatetime: this.options.expire_at || null, internalUse: this.options.internal, fromOrder: this.options.fromOrder, warehouse_id: this.options.warehouse })
          }, 1000);
	},
	updateBatch() {
    this.loading = true;
    setTimeout(() => {
    this.$bus.$emit("update-batch", {id: this.options.id, newPrice: this.options.purchase_price, newStock: this.options.stock, newDatetime: this.options.expire_at || null, internalUse: this.options.internal, fromOrder: this.options.fromOrder, warehouse_id: this.options.warehouse })
          }, 1000);
  },
	close() {
		// this.closeDrawer(this.level);
		this.$root.closeDrawer(1);	
	},	
  }
};
</script>

