<template>
  <div class="wrap is-size-7">
    <div class="columns is-mobile is-multiline">
      <ul class="column is-6-tablet is-12-mobile">
        <li class="is-size-6 mb-1">
          <b>{{ $t("shipping_address") }}</b>
          <span
            v-if="editable && $can('update', 'orders')"
            @click="openShippingList()"
          >
            <b-icon icon="pencil" size="is-small" class="ml-1 is-clickable" />
          </span>
        </li>
        <li>{{ addresses.shipping.fname }} {{ addresses.shipping.lname }}</li>
        <li>
          {{ addresses.shipping.country_code }}
          {{ addresses.shipping.phone && addresses.shipping.phone.number }}
        </li>
        <li v-if="addresses.shipping.company">
          {{ addresses.shipping.company }}
          <template v-if="addresses.shipping.pickup_ref">
            - {{ addresses.shipping.pickup_ref }}
          </template>
        </li>
        <li>{{ addresses.shipping.address1 }}</li>
        <li v-if="addresses.shipping.address2">
          {{ addresses.shipping.address2 }}
        </li>
        <li>
          {{ addresses.shipping.city }}, {{ addresses.shipping.zip }} -
          <span>{{ addresses.shipping.country.country }}</span>
        </li>
        <li>{{ addresses.shipping.phone }}</li>
        <li>
          <b>{{ $t("by") }} {{ options.carriers[0] }}</b>
          <b-dropdown
            v-if="options.carriers && options.carriers.length > 1"
            class="ml-2"
            aria-role="list"
            position="is-bottom-right"
            :triggers="['hover']"
          >
            <b-tag
              slot="trigger"
              rounded
            >
              +{{ options.carriers.length - 1 }}
              <b-icon
                icon="chevron-down"
                size="is-small"
                class="is-clickable ml-1"
              />
            </b-tag>

            <template v-for="(c, index) in options.carriers">
              <b-dropdown-item
                v-if="index > 0"
                :key="c"
                :focusable="false"
                custom
                aria-role="listitem"
              >
                {{ c }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </li>
      </ul>

      <ul class="column is-6-tablet is-12-mobile">
        <li class="is-size-6 mb-1">
          <b>{{ $t("billing_address") }}</b>
        </li>
        <li>{{ addresses.billing.fname }} {{ addresses.billing.lname }}</li>
        <li>
          {{ addresses.billing.phone && addresses.billing.phone.code }}
          {{ addresses.billing.phone && addresses.billing.phone.number }}
        </li>
        <li v-if="addresses.billing.company">
          {{ addresses.billing.company }}
        </li>
        <li>{{ addresses.billing.address1 }}</li>
        <li v-if="addresses.billing.address2">
          {{ addresses.billing.address2 }}
        </li>
        <li>
          {{ addresses.billing.city }}, {{ addresses.billing.zip }} -
          <span>{{ addresses.billing.country.country }}</span>
        </li>
        <li>{{ addresses.billing.phone }}</li>
        <li v-if="notes">
          <b>{{ notes }}</b>
        </li>
        <li v-if="incoterm">
          <b>{{ $t("incoterm") }}:</b> {{ incoterm }}
        </li>
      </ul>
    </div>

    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <p class="is-size-6 mb-2">
          <b>{{ $tc("product", 2) }}</b>
          <span class="is-size-7">
            - {{ $t("total") }}: {{ count.qty }}, {{ $tc("sku", 2) }}:
            {{ count.sku }}
          </span>
        </p>
        <ul v-if="items.length > 0" class="cart">
          <li
            v-for="(item, item_index) in items"
            :key="item_index"
            class="columns is-mobile"
            :class="{
              'bg-updated':
                (item.type === 'pack' && updated_item === item.combo) ||
                (item.type === 'product' && updated_item === item.item_id),
              'bg-deleted': item.deleted,
            }"
          >
            <div class="column is-2-tablet is-3-mobile py-0">
              <img
                width="80"
                height="80"
                :src="
                  (item.img &&
                    `${item.img.url}${item.img.path}${item.img.filename}-w80h80@2x.${item.img.version}.jpg`) ||
                  '/img/blank.svg'
                "
                :alt="item.name"
              />
            </div>

            <div class="column is-10-tablet is-9-mobile">
              <div class="columns is-mobile">
                <div class="column det is-7">
                  <span>
                    <b>{{ item.name }}</b>
                  </span>

                  <ul
                    v-bind:class="{ pack: item.type === 'pack' }"
                    class="refs"
                  >
                    <li v-for="(ref, index) in item.refs" :key="index">
                      <b>
                        {{ item.type === "pack" ? ref.name + " - " : "" }}
                        {{ ref.size }}
                        <template v-for="(attr, i) in ref.attributes">
                          <span v-if="attr.value" :key="'attr' + i">
                            - {{ attr.value }}
                          </span>
                        </template>
                      </b>
                      <span
                        v-if="ref['batches'][0]['expire_at'] !== null"
                        class="is-block"
                        >{{ ref["batches"][0]["expire_at"]["formatted"] }}</span
                      >
                    </li>
                  </ul>

                  <template v-if="editable && $can('delete', 'orders')">
                    <transition name="slide-fade" mode="out-in">
                      <div
                        v-if="
                          deleteProdId ==
                          (item.type == 'pack' ? item.combo : item.id)
                        "
                        class="mt-1 is-size-8"
                      >
                        <b class="has-text-danger">{{ $t("are_you_sure") }}</b>
                        <span
                          class="ml-2 has-text-grey is-clickable"
                          @click="deleteProdId = null"
                        >
                          <b-icon
                            type="is-danger"
                            size="is-small"
                            icon="close-circle"
                          />
                          {{ $t("no") }}
                        </span>
                        <span
                          class="ml-2 has-text-grey is-clickable"
                          @click="deleteItem(item_index, item)"
                        >
                          <b-icon
                            type="is-success"
                            size="is-small"
                            icon="check-circle"
                          />
                          {{ $t("yes") }}
                        </span>
                      </div>
                      <span
                        v-else
                        class="is-clickable is-size-8"
                        @click="
                          deleteProdId =
                            item.type == 'pack' ? item.combo : item.id
                        "
                      >
                        <b-icon
                          icon="delete"
                          size="is-small"
                          type="is-danger"
                        />
                        {{ $t("delete") }}
                      </span>
                    </transition>
                  </template>
                </div>

                <div class="column has-text-right is-2">
                  <div class="editable">
                    <span
                      class="edit right"
                      @click="openQty(item_index, item.qty)"
                    >
                      <input
                        :placeholder="$t('qty')"
                        inputType="number"
                        autofocus
                        :name="
                          'qty' +
                          (item.type == 'pack' ? item.combo : item.item_id)
                        "
                        :value="qty"
                        :class="
                          editQty == null ||
                          editQty !=
                            (item.type == 'pack' ? item.combo : item.item_id)
                            ? ''
                            : 'show'
                        "
                        @blur="editQty = null"
                        @keyup.enter="updateQty($event, item_index, item)"
                      />
                      {{ item.qty }}
                    </span>
                  </div>
                </div>

                <div class="column has-text-right is-3">
                  <div class="editable">
                    <b-tooltip
                      type="is-primary"
                      position="is-left"
                      :triggers="['click']"
                      :auto-close="['outside']"
                      :active="item.profit != null && closeTooltip == false"
                    >
                      <template v-if="item.profit" v-slot:content>
                        {{ $t("gross_margin") }}:
                        {{ updateProfit ? profit : item.profit.value
                        }}{{ item.price.currency.symbol }} ({{
                          updateProfit ? profitPercent : item.profit.percent
                        }}%)
                      </template>

                      <span
                        class="edit right"
                        @click="
                          openPrice(item_index, item.price.total.selling.value)
                        "
                      >
                        <input
                          :placeholder="$t('price')"
                          inputType="number"
                          step=".01"
                          autofocus
                          :name="
                            'price' +
                            (item.type == 'pack' ? item.combo : item.item_id)
                          "
                          :value="item.price.unit.selling.value"
                          :class="
                            editPrice == null ||
                            editPrice !=
                              (item.type == 'pack' ? item.combo : item.item_id)
                              ? ''
                              : 'show'
                          "
                          @input="updateMargin($event, item_index, item)"
                          @blur="
                            editPrice = null;
                            updateProfit = false;
                          "
                          @keyup.enter="updatePrice($event, item_index, item)"
                        />
                        {{ item.price.total.selling.formatted }}
                      </span>
                      <div>
                        <!-- <b-tag
                          v-if="item.batches[0].internal"
                          type="is-primary"
                          size="is-small"
                        >
                          {{ $t("internal") }}
                        </b-tag> -->
                        <b-tag
                          v-if="item.price.unit.selling.value == 0"
                          class="ml-1"
                          type="is-success"
                          size="is-small"
                        >
                          {{ $tc("gift", 1) }}
                        </b-tag>
                        <b-tag
                          v-if="item.price.unit.selling.value < 0"
                          class="ml-1"
                          type="is-danger"
                          size="is-small"
                        >
                          {{ $t("refunded") }}
                        </b-tag>
                        <b-tag
                          v-if="isInternalBatch(item)"
                          class="ml-1"
                          type="is-info"
                          size="is-small"
                        >
                          {{ $t("internal") }}
                        </b-tag>
                      </div>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </li>
        </ul>
      </div>
      <div v-if="$can('create', 'orders') && editable" class="column is-12">
        <transition name="slide-fade" mode="out-in">
          <ValidationObserver
            v-if="show_new_product_form"
            ref="productObserver"
            v-slot="{ handleSubmit }"
            tag="div"
            class="mt-3"
          >
            <form
              autocomplete="off"
              class="columns is-2 is-mobile is-multiline is-variable mb-0"
            >
              <div
                class="column is-12 is-flex is-justify-content-space-between is-align-items-center"
              >
                <b>{{ $t("add_product") }}</b>

                <div>
                  <b-button
                    :disabled="new_product_loading"
                    :loading="new_product_loading"
                    size="is-small"
                    @click="
                      show_new_product_form = false;
                      resetNewProduct();
                    "
                  >
                    <b-icon icon="close" type="is-danger" size="is-small" />
                  </b-button>

                  <b-button
                    :disabled="new_product_loading"
                    :loading="new_product_loading"
                    size="is-small"
                    class="ml-2"
                    @click="handleSubmit(saveNewProduct)"
                  >
                    <b-icon icon="check" type="is-success" size="is-small" />
                  </b-button>
                </div>
              </div>

              <div class="column is-6-tablet is-12-mobile pr-0">
                <SearchProduct
                  :key="new_product.id"
                  :avs="true"
                  :autofocus="!new_product.id"
                  :label="$tc('sku_or_product')"
                  size="is-small"
                  :sites-ids="[siteId]"
                  :selected-prod="new_product.label || new_product.id"
                  :warehouse-id="options.warehouse_id"
                  :order-id="orderId"
                  @selectItem="
                    (product) => {
                      new_product.id = product.id;
                      new_product.label = product.label;
                      checkProdId();
                    }
                  "
                />
              </div>

              <div class="column is-3-tablet is-6-mobile">
                <b-field :label="$tc('batch', 1)">
                  <b-select
                    v-model="new_product.batch"
                    vid="batch"
                    size="is-small"
                    :placeholder="
                      new_product.avs === 0 ? $t('sold_out') : $t('default')
                    "
                    expanded
                    :disabled="!new_product.id"
                  >
                    <option :value="null">
                      {{
                        new_product.avs === 0 ? $t("sold_out") : $t("default")
                      }}
                    </option>
                    <option v-if="$can('create', 'purchasing')" :value="0">
                      ---- {{ $t("add_batch") }} ----
                    </option>
                    <option
                      v-for="opt in batches"
                      :key="opt.id"
                      :value="opt.id"
                    >
                      {{ $t("stock") }}: {{ opt.stock }} -
                      {{ opt.price.formatted }}
                      {{ opt.expire_at && "- " + opt.expire_at.formatted }}
                      {{
                        opt.internal ? "(" + $t("internal_use") + ")" : ""
                      }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="column is-1-tablet is-2-mobile px-0">
                <b-input-validation
                  v-model="new_product.qty"
                  vid="qty"
                  size="is-small"
                  :placeholder="
                    new_product.avs
                      ? `ex: ${new_product.avs}`
                      : placeholders.number['1']
                  "
                  :rules="
                    new_product.id
                      ? 'required|min_value:1|max_value:' + maxBatchQty
                      : ''
                  "
                  :label="$t('qty')"
                  name="qty"
                  input-type="number"
                  :disabled="
                    !new_product.id ||
                    (new_product.avs === 0 && !new_product.batch)
                  "
                  @blur="onBlurQty()"
                  @input="checkMax()"
                />
              </div>

              <div class="column is-2-tablet is-4-mobile">
                <b-input-validation
                  v-model="new_product.price"
                  :label="$t('price')"
                  :suffix="summary.currency.symbol"
                  vid="price"
                  size="is-small"
                  input-type="number"
                  step=".01"
                  :placeholder="placeholders.price.decimal"
                  rules="required|min_value:0"
                  :disabled="
                    !new_product.id ||
                    (new_product.avs === 0 && !new_product.batch)
                  "
                />
              </div>
            </form>
            <hr class="mt-0" />
          </ValidationObserver>
        </transition>

        <transition name="slide-fade" mode="out-in">
          <NewPack
            v-if="show_new_pack_form"
            :order-id="orderId"
            :lang-iso="langIso"
            :site-id="siteId"
            @close="show_new_pack_form = false"
            @update="packAdded"
          />
        </transition>

        <transition name="slide-fade" mode="out-in">
          <div v-if="!show_new_product_form || !show_new_pack_form">
            <b-button
              :disabled="show_new_product_form"
              size="is-small"
              type="is-primary"
              outlined
              @click="show_new_product_form = true"
            >
              {{ $t("add_product") }}
            </b-button>

            <b-button
              :disabled="show_new_pack_form"
              class="ml-2"
              size="is-small"
              type="is-primary"
              outlined
              @click="showPackForm"
            >
              {{ $t("add_pack") }}
            </b-button>
          </div>
        </transition>
      </div>
    </div>

    <div
      class="columns is-mobile is-multiline is-variable is-0 mt-4 has-text-right"
    >
      <div class="column is-9-tablet is-7-mobile">
        {{ $t("subtotal") }}
      </div>
      <div class="column is-3-tablet is-5-mobile">
        {{ summary.subtotal.formatted }}
      </div>
      <div class="column is-9-tablet is-7-mobile">
        {{ $t("discount") }}
      </div>
      <div class="column is-3-tablet is-5-mobile editable">
        <!-- Start edit discount -->
        <span class="edit right" @click="openDiscount()">
          <input
            :placeholder="$t('discount')"
            inputType="number"
            step=".01"
            autofocus
            name="discount"
            value=""
            :class="editDiscount ? 'show' : ''"
            @blur="editDiscount = false"
            @keyup.enter="updateDiscount($event)"
          />
          {{ summary.discount ? summary.discount.formatted : "-" }}
        </span>
        <!-- End edit discount -->
      </div>
      <div class="column is-9-tablet is-7-mobile">
        <span
          v-if="
            summary.shipping.new !== summary.shipping.value &&
            $can('update', 'orders') &&
            editablePricing
          "
          class="is-clickable"
          @click="refreshShipping()"
        >
          <b-icon size="is-small" icon="refresh" type="is-success" />
        </span>
        {{ $t("shipping") }}
      </div>
      <div class="column is-3-tablet is-5-mobile editable">
        <span class="edit right" @click="openShipping()">
          <input
            :placeholder="$t('shipping')"
            inputType="number"
            step=".01"
            autofocus
            name="shipping"
            :value="summary.shipping.value"
            :class="editShipping ? 'show' : ''"
            @blur="editShipping = false"
            @keyup.enter="updateShipping($event.target.value)"
          />
          {{ summary.shipping.formatted }}
        </span>
      </div>
      <div class="column is-9-tablet is-7-mobile">
        <transition name="slide-fade" mode="out-in">
          <template
            v-if="summary.vat.value > 0 && $can('delete', 'orders.vat')"
          >
            <div v-if="confirmDeleteVat" class="mt-1">
              <b class="has-text-danger">{{ $t("are_you_sure") }}</b>
              <span
                class="ml-2 has-text-grey is-clickable"
                @click="confirmDeleteVat = false"
              >
                <b-icon type="is-danger" size="is-small" icon="close-circle" />
                {{ $t("no") }}
              </span>
              <span
                class="ml-2 has-text-grey is-clickable"
                @click="deleteVat()"
              >
                <b-icon type="is-success" size="is-small" icon="check-circle" />
                {{ $t("yes") }}
              </span>
            </div>
            <span v-else>
              <span v-if="editablePricing" @click="confirmDeleteVat = true">
                <b-icon
                  icon="delete"
                  class="is-clickable"
                  size="is-small"
                  type="is-danger"
                />
              </span>
              {{ $tc("vat", 1) }}
            </span>
          </template>
          <span v-else>{{ $tc("vat", 1) }}</span>
        </transition>
      </div>
      <div class="column is-3-tablet is-5-mobile">
        {{ summary.vat.formatted }}
      </div>
      <div class="column is-9-tablet is-7-mobile">
        <b>{{ $t("total_order") }}</b>
      </div>
      <div class="column is-3-tablet is-5-mobile">
        <b>{{ summary.total.formatted }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";
import NewPack from "@/components/forms/order/NewPack.vue";
import { ValidationProvider } from "vee-validate";
import moment from "moment";

export default {
  components: {
    SearchProduct,
    NewPack,
    // ValidationProvider,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    notes: {
      type: String,
      default: null,
    },
    incoterm: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    editablePricing: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Object,
      required: true,
    },
    warehouses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      addresses: this.options.addresses,
      orderId: this.options.orderId,
      userId: this.options.userId,
      items: this.options.items,
      summary: this.options.summary,
      siteId: this.options.site_id,
      langIso: this.options.lang_iso,
      batches: [],

      order: [],
      // edit variables
      price: null,
      profit: null,
      profitPercent: null,
      qty: null,
      deleteProdId: null,
      editPrice: null,
      editQty: null,
      confirmDeleteVat: false,
      updateProfit: false,

      // new product variable
      show_new_product_form: false,
      new_product_loading: false,
      new_product: {
        valid: false,
        id: null,
        qty: 1,
        avs: null,
        price: null,
        batch: null,
      },
      deals: false,

      // new product variable
      // TODO:
      show_new_pack_form: false,

      // edit shipping
      editShipping: false,

      // edit discount
      editDiscount: false,

      //delete product
      delete_this: null,
      delete_loading: false,

      //delete vat
      delete_vat_confirm: false,

      total: 0,
      total_order: 0,
      free_from: null,

      add_to_cart_loading: true,
      qty_load_more: false,
      qty_load_less: false,
      voucher_loading: false,
      voucher_message: [],
      detail_loading: false,
      closeTooltip: false,

      showAddModal: false,
      newPrice: null,
      newStock: null,
      internalUse: false,
      newDatetime: null,
      maxBatchQty: null,
      batch_purchasePrice: null,

      updated_item: null,
      deleted_item: null,
    };
  },
  watch: {
    // hide all text fields id editable = false
    editable(newVal) {
      if (!newVal) {
        this.editShipping = false;
        this.editDiscount = false;
        this.editPrice = null;
        this.editQty = null;
        this.delete_this = null;
        this.confirmDeleteVat = false;
      }
    },
    "new_product.batch"(newValue) {
      if (newValue == null) {
        this.maxBatchQty = this.new_product.avs;
      } else this.checkMax();
      if (newValue == 0) {
        this.openBatchDrawer(
          this.new_product.expiry_required,
          null,
          null,
          null,
          0,
          false,
          true,
          this.options.warehouse_id
        );
      }
    },
  },
  mounted() {
    this.$bus.$on("add-new-batch-from-order", (params) => {
      this.addNewBatch(
        params.newPrice,
        params.newStock,
        params.newDatetime,
        params.internalUse,
        params.fromOrder,
        params.warehouse_id
      );
    });

    this.$bus.$on("update-order", (data) => {
      this.updateOrderOptions({
        summary: data.summary,
        details: data.details,
      });

      if (data.items) this.items = data.items;
      this.addresses = data.addresses;
    });
    // this.$bus.$on("close-drawer", () => {
    //   if (this.summary.shipping.new !== this.summary.shipping.value) {
    //     this.$bus.$emit("open_shipping_modal", {
    //       orderId: this.orderId,
    //       summary: this.summary,
    //     });
    //   }
    // });

    this.$bus.$on("update-order-options", (params) => {
      if (params.items) this.items = params.items;
    });
  },
  destroyed() {
    this.$bus.$off("update-order");
    this.$bus.$off("update-order-options");
    this.$bus.$off("close-drawer");
    this.$bus.$off("add-new-batch-from-order");
  },
  methods: {
    showPackForm() {
      this.show_new_pack_form = true;
    },
    packAdded(res) {
      this.editPrice = null;
      this.editQty = null;
      this.delete_this = null;
      this.updateOrderOptions({
        summary: res.data.summary,
        details: res.data.details,
        count: res.data.count,
      });
      let item = res.data.item;
      this.items.push(item);
      this.deals = res.data.deals;
      this.updated_item = res.data.item.combo;
      setTimeout(() => {
        this.updated_item = null;
      }, 2000);
    },
    openShippingList() {
      let options = {
        level: 1,
        order_id: this.orderId,
        user_id: this.userId,
        index: 0,
        address: this.addresses.shipping,
        countries: [],
      };
      this.openDrawer("orderAddresses", options);
    },
    openBatchDrawer(
      expiryRequired,
      id,
      stock,
      purchase_price,
      datetime,
      internal,
      add,
      warehouse,
      warehouses
    ) {
      let options = {
        level: 1,
        add: add,
        width: "small",
        internal: internal,
        currency: this.summary.currency.symbol,
        fromOrder: true,
        warehouse: warehouse,
        warehouses: this.warehouses,
        order_purchasePrice: this.batch_purchasePrice,
        expiryRequired: expiryRequired,
      };
      this.openDrawer("editBatch", options);
    },
    refreshShipping() {
      if (this.summary.shipping.new !== this.summary.shipping.value) {
        this.updateShipping(this.summary.shipping.new);
      }
    },
    openShipping() {
      if (this.$can("update", "orders") && this.editablePricing) {
        //if (this.editable) {
        this.editShipping = true;
        //} else return false;
      }
    },
    updateShipping(value) {
      if (this.$can("update", "orders") && this.editablePricing) {
        this.$axios
          .patch("ecommerce/orders/" + this.orderId, {
            shipping_amount: value,
          })
          .then((res) => {
            this.updateOrderOptions({
              summary: res.data.summary,
              details: res.data.details,
            });
            this.editShipping = false;
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    openDiscount() {
      if (this.$can("update", "orders") && this.editablePricing) {
        //if (this.editable) {
        this.editDiscount = true;
        //} else return false;
      }
    },
    updateDiscount(e) {
      if (this.$can("update", "orders") && this.editablePricing) {
        this.$axios
          .patch("ecommerce/orders/" + this.orderId, {
            voucher_id: e.target && e.target.value ? e.target.value : 0,
          })
          .then((res) => {
            this.updateOrderOptions({
              summary: res.data.summary,
              details: res.data.details,
            });
            this.editDiscount = false;
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    openPrice(index, price) {
      if ((this.editablePricing || (price < 0 && this.options.sid != 10 && this.options.sid != 11) )&& this.$can("update", "orders")) {
        this.closeTooltip = false;
        //if (this.editable) {
        this.price = price;
        let item = this.items[index];
        this.editPrice = item.item_id || item.combo;
        this.$set(this.items, index, item);
        //} else return false;
      }
    },
    openQty(index, qty) {
      if (this.editable && this.$can("update", "orders")) {
        this.qty = qty;
        let item = this.items[index];
        this.editQty = item.item_id || item.combo;
        this.$set(this.items, index, item);
      } else return false;
    },
    updateMargin(event, index, item) {
      this.updateProfit = true;
      let profitValue = parseFloat(this.items[index].profit.value);
      let qty = item.qty;
      let margin =
        (parseFloat(event.target.value) +
          profitValue / qty -
          parseFloat(this.items[index].price.unit.selling.value)) *
        qty;
      let percent =
        event.target.value == 0
          ? -100
          : (parseFloat(margin) * 100) /
            (parseFloat(event.target.value) * item.qty);
      this.profit = margin.toFixed(2);
      this.profitPercent = Math.round(percent);
    },
    updatePrice(e, index, item) {
      this.closeTooltip = true;
      if (item.type == "pack") {
        this.$axios
          .patch(`ecommerce/orders/${this.orderId}`, {
            pack_id: item.id,
            combo: item.combo,
            price: e.target && e.target.value && parseFloat(e.target.value),
          })
          .then((res) => {
            this.items[index] = res.data.item;
            this.editPrice = null;
            this.delete_this = null;
            this.updateOrderOptions({
              summary: res.data.summary,
              details: res.data.details,
            });
            this.editQty = null;
            this.qty = null;
            this.updated_item = this.items[index].combo;
            setTimeout(() => {
              this.updated_item = null;
            }, 2000);
            if (res.data.deals)
              this.$root.notify(
                this.$t("ongoing_deals_on_product"),
                "is-warning"
              );
          })
          .catch((e) => this.$root.clientError(e));
      } else {
        let arr = [];
        for (let i = 0; i < item.batches.length; i++) {
          const elt = item.batches[i];
          arr.push(elt.item_id);
        }
        this.$axios
          .patch("ecommerce/orders/" + this.orderId, {
            price: e.target && e.target.value && parseFloat(e.target.value),
            items: arr,
          })
          .then((res) => {
            this.items[index] = res.data.item;
            this.editPrice = null;
            this.editQty = null;
            this.delete_this = null;
            this.updateOrderOptions({
              summary: res.data.summary,
              details: res.data.details,
            });
            this.price = null;
            this.updated_item = this.items[index].item_id;
            setTimeout(() => {
              this.updated_item = null;
            }, 2000);
            if (res.data.deals)
              this.$root.notify(
                this.$t("ongoing_deals_on_product"),
                "is-warning"
              );
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    updateQty(e, index, item) {
      if (this.$can("update", "orders")) {
        if (item.type == "pack") {
          this.$axios
            .patch(`ecommerce/orders/${this.orderId}`, {
              pack_id: item.id,
              combo: item.combo,
              qty: e.target && e.target.value,
            })
            .then((res) => {
              this.items[index] = res.data.item;
              this.editPrice = null;
              this.delete_this = null;
              this.updateOrderOptions({
                summary: res.data.summary,
                details: res.data.details,
                count: res.data.count,
              });
              this.editQty = null;
              this.qty = null;
              this.updated_item = this.items[index].combo;
              setTimeout(() => {
                this.updated_item = null;
              }, 2000);
              if (res.data.deals)
                this.$root.notify(
                  this.$t("ongoing_deals_on_product"),
                  "is-warning"
                );
            })
            .catch((e) => this.$root.clientError(e));
        } else {
          this.$axios
            .patch("ecommerce/orders/" + this.orderId, {
              qty: e.target && e.target.value,
              items: [item.item_id],
            })
            .then((res) => {
              this.items[index] = res.data.item;
              this.editPrice = null;
              this.delete_this = null;
              this.updateOrderOptions({
                summary: res.data.summary,
                details: res.data.details,
                count: res.data.count,
              });
              this.editQty = null;
              this.qty = null;
              this.updated_item = this.items[index].item_id;
              setTimeout(() => {
                this.updated_item = null;
              }, 2000);
              if (res.data.deals)
                this.$root.notify(
                  this.$t("ongoing_deals_on_product"),
                  "is-warning"
                );
            })
            .catch((e) => this.$root.clientError(e));
        }
      }
    },
    checkProdId() {
      if (this.new_product.id) {
        this.$axios
          .get(
            "ecommerce/catalog/" + this.orderId + "/sku/" + this.new_product.id
          )
          .then((res) => {
            this.new_product.avs = res.data.avs;
            this.maxBatchQty = res.data.avs;
            this.new_product.qty = res.data.avs > 0 ? 1 : 0;
            this.new_product.price = res.data.price.value;
            this.new_product.expiry_required = res.data.expiry_required;
            this.batches = res.data.batches;
            this.batch_purchasePrice = res.data.purchase_price.value;
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    updateOrderOptions(params) {
      if (params.summary) this.summary = params.summary;
      params["id"] = this.orderId;
      this.$bus.$emit("update-order-options", params);
    },
    saveNewProduct() {
      let params = {
        id: this.new_product.id,
        qty: this.new_product.qty,
        price: this.new_product.price || null,
        stock_id: this.new_product.batch,
      };
      this.new_product_loading = true;
      this.$axios
        .post("ecommerce/orders/" + this.orderId, params)
        .then((res) => {
          this.editPrice = null;
          this.editQty = null;
          this.delete_this = null;
          this.updateOrderOptions({
            summary: res.data.summary,
            details: res.data.details,
            count: res.data.count,
          });
          let item = res.data.item;
          this.items.push(item);
          this.updated_item = res.data.item.item_id;
          setTimeout(() => {
            this.updated_item = null;
          }, 2000);
          this.deals = res.data.deals;
          this.batches = [];
          this.new_product = {
            valid: false,
            id: null,
            qty: 1,
            avs: null,
            price: null,
            label: "",
            batch: null,
          };
          if (res.data.deals)
            this.$root.notify(
              this.$t("ongoing_deals_on_product"),
              "is-warning"
            );
          this.$refs.productObserver.reset();
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => {
          this.new_product_loading = false;
          // this.show_new_product_form = false;
        });
    },
    deleteItem(index, item) {
      if (this.editable) {
        this.delete_loading = true;
        if (item.type == "pack") {
          this.$axios
            .delete(
              `ecommerce/orders/${this.orderId}/pack/${item.id}/${item.combo}`
            )
            .then((res) => {
              this.updateOrderOptions({
                summary: res.data.summary,
                details: res.data.details,
                count: res.data.count,
              });
              this.deleteProdId = null;
              this.items[index].deleted = true;
              setTimeout(() => {
                for (let i = 0; i < this.items.length; i++) {
                  const j = this.items[i].combo;
                  if (j === this.items[index].combo) this.items.splice(i, 1);
                }
              }, 1000);
              if (res.data.deals)
                this.$root.notify(
                  this.$t("ongoing_deals_on_product"),
                  "is-warning"
                );
            })
            .catch((e) => {
              this.$root.clientError(e);
              this.delete_loading = false;
            })
            .finally(() => (this.delete_loading = false));
        } else {
          let item_ids = [];
          item_ids = item.batches.map((ib) => {
            return ib.item_id;
          });
          this.$axios
            .post("ecommerce/orders/" + this.orderId + "/delete", {
              items: item_ids,
            })
            .then((res) => {
              this.items[index].deleted = true;
              setTimeout(() => {
                for (let i = 0; i < this.items.length; i++) {
                  const j = this.items[i].id;
                  if (j === this.items[index].id) this.items.splice(i, 1);
                }
              }, 1000);
              this.updateOrderOptions({
                summary: res.data.summary,
                details: res.data.details,
                count: res.data.count,
              });
              this.deleteProdId = null;
              if (res.data.deals)
                this.$root.notify(
                  this.$t("ongoing_deals_on_product"),
                  "is-warning"
                );
            })
            .catch((e) => {
              this.$root.clientError(e);
              this.delete_loading = false;
            })
            .finally(() => (this.delete_loading = false));
        }
      }
    },
    deleteVat() {
      this.$axios
        .patch("ecommerce/orders/" + this.orderId, {
          vat: "ex",
        })
        .then((res) => {
          this.updateOrderOptions({
            summary: res.data.summary,
            details: res.data.details,
          });
          this.editDiscount = false;
        })
        .catch((e) => this.$root.clientError(e));
    },
    onBlurQty() {
      if (this.new_product.qty == "") this.new_product.qty = 1;
    },
    checkMax() {
      if (this.new_product.batch) {
        let max = this.batches.find(
          (x) => x.id === this.new_product.batch
        ).stock;
        this.maxBatchQty = max;
        this.new_product.qty =
          Number(this.new_product.qty) >= max ? max : this.new_product.qty;
      }
    },
    addNewBatch(price, stock, date, internalUse, fromOrder, warehouse_id) {
      if (fromOrder) {
        this.loading = true;
        let url = "catalog/sku/" + this.new_product.id + "/batches";
        this.$axios
          .post(url, {
            prod_ref_id: parseInt(this.new_product.id),
            purchase_price: parseFloat(price),
            stock: parseInt(stock),
            internal: Number(internalUse),
            expire_at: moment(date).add(1, "d").toISOString(),
            warehouse_id: warehouse_id,
          })
          .then((res) => {
            this.new_product.batch = res.data.id;
            this.new_product.qty = 1;
            let newBatch = {
              id: res.data.id,
              stock: res.data.stock,
              price: res.data.purchase_price,
              expire_at: res.data.expire_at,
              internal: res.data.internal,
              warehouse: res.data.warehouse,
            };
            this.batches.push(newBatch);
            this.closeDrawer(1);
            this.new_product.qty = stock;
            this.$root.notify(this.$t("successfully_created"), "is-success");
          })
          .catch((e) => this.$root.clientError(e))
          .finally(() => (this.loading = false));
      }
    },
    resetNewProduct() {
      this.new_product = {
        valid: false,
        id: null,
        qty: 1,
        avs: null,
        price: null,
        batch: null,
      };
    },

    isInternalBatch(item) {
      if (item && item.batches) {
        for (let i = 0; i < item.batches.length; i++) {
          if (item.batches[i].internal) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
