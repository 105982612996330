<template>
  <div
    v-on-clickaway="away"
    class="custom-select"
  >
    <b-field
      :label="label"
      class="mb-0"
      :class="size"
    >
      <p
        :key="innerValue.id"
        class="control is-clickable"
        @click="showResult = true"
      >
        {{ innerValue.label }}
        <span
          v-if="logisticStatus"
          class="has-text-grey-light ml-1"
        >
          [{{ logisticStatus.label }}]</span>
      </p>
    </b-field>
    <div
      class="result"
      :class="{ active: showResult }"
    >
      <template v-for="item in data">
        <div
          :key="item.id"
          class="item py-3 px"
        >
          <transition
            name="slide-fade"
            mode="out-in"
          >
            <div
              v-if="tempStatus == item.id && item.id >= 9 && item.id != 10 && item.id != 15"
              class="is-flex is-align-items-center is-justify-content-space-between"
            >
              <div>
                <b class="has-text-danger">{{ $t("are_you_sure") }}</b>
                <span
                  class="ml-2 has-text-grey"
                  @click="tempStatus = null"
                >
                  <b-icon
                    type="is-danger"
                    size="is-small"
                    icon="close-circle"
                  />
                  {{ $t("no") }}
                </span>
                <span
                  class="ml-2 has-text-grey"
                  @click="saveStatus(item.id)"
                >
                  <b-icon
                    type="is-success"
                    size="is-small"
                    icon="check-circle"
                  />
                  {{ $t("yes") }}
                </span>
              </div>

              <span class="ml-2 is-flex mb-1">
                <b-checkbox
                  v-model="restock"
                  size="is-small"
                  type="is-info"
                >
                  {{ $t("restock_products") }}
                </b-checkbox>
              </span>
            </div>
            <p
              v-else
              @click="selectStatus(item.id)"
            >
              <template v-if="innerValue.id == item.id">
                <b-icon
                  class="mr-1"
                  size="is-small"
                  icon="check"
                />
                <b>{{ item.label }}</b>
              </template>
              <template v-else>
                {{ item.label }}
              </template>
            </p>
          </transition>
          <b-loading
            v-if="tempStatus == item.id"
            v-model="loading"
            :is-full-page="false"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway2";

export default {
  mixins: [clickaway],
  props: {
    logisticStatus: {
      type: Object,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    value: {
      type: [Array, String, Object, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    orderId: {
      type: [String, Number],
      required: true
    },
    size: {
      type: String,
      default: "is-small"
    }
  },
  data() {
    return {
      tempStatus: null,
      restock: true,
      loading: false,
      showResult: false,
      innerValue: this.data.find(elt => elt.id == this.value)
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal.id);
    },
    value(newVal) {
      this.innerValue = this.data.find(elt => elt.id == newVal);
    }
  },
  methods: {
    away() {
      this.showResult = false;
    },
    selectStatus(id) {
      if (this.innerValue.id !== id) {
        this.tempStatus = id;
        if ( id == 10 ) {
          this.$emit("openCancelModal");
        }
        else if (id < 9 || id == 15 ) {
          this.saveStatus(id);
        }   
      } 
      else this.away();
    },
    saveStatus(status_id) {
      this.loading = true;

      let data = {
        status_id: status_id,
        restock: status_id >= 9 ? this.restock : false
      };

      this.$axios
        .patch("ecommerce/orders/" + this.orderId, data)
        .then(res => {
          this.innerValue = this.data.find(s => s.id == status_id);
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.details,
            actions: res.data.actions,
            summary: res.data.summary,
            items: res.data.items
          });
          this.$bus.$emit("update-order-payments",{
            payments: res.data.payments
          });
          this.$bus.$emit("update-form-payments", {
            payments: res.data.payments
          });
        })
        .catch(e => this.clientError(e))
        .finally(() => {
          this.away();
          this.loading = false;
          this.tempStatus = null;
          this.restock = true;
        });
    }
  }
};
</script>
